import { NstGroupProps } from './types';


export const NstGroupResources: any = {
  "year": [
    2021,
    2022,
    2023
  ],
  "version": [
      "2021K01",
      "2021K02",
      "2021K03",
      "2021K04",
      "2021K05",
      "2021K06",
      "2021K07",
      "2021K08"
  ],
  "make": [
    "CBU",
    "PBP",
    "VAN",
    "DENZA"
  ],
  "unConfirmCount": 0,
  "data": [
    {
      "brand": "brand",
      "typeClass": "typeClass",
      "nstGroup": "nstGroup",
      "nstGroupName": 'nstGroupName',
      "jan": {
          "id": "1111",
          "msrp": "1223.00",
          "override": null,
          "status": null,
          "isCurrentMonth": true
      },
      "feb": null,
      "mar": null,
      "apr": null,
      "may": null,
      "jun": null,
      "jul": null,
      "aug": {
        "id": "1212",
        "msrp": "1212",
        "override": null,
        "status": null,
        "isCurrentMonth": true
      },
      "sep": {
        "id": '45465',
        "msrp": "null",
        "override": "6879",
        "status": null,
        "isCurrentMonth": false
      },
      "oct": {
        "id": "687980",
        "msrp": "90809",
        "override": "4576879",
        "status": null,
        "isCurrentMonth": false
      },
      "nov": {
        "id": "6767808-9",
        "msrp": null,
        "override": null,
        "status": null,
        "isCurrentMonth": false
      },
      "dec": {
        "id": "7879809-0",
        "msrp": null,
        "override": null,
        "status": null,
        "isCurrentMonth": false
      }
    },
    {
      "brand": "brand2",
      "typeClass": "typeClass2",
      "nstGroup": "nstGroup2",
      "nstGroupName": 'nstGroupName2',
      "jan": {
          "id": "1111",
          "msrp": "1223.00",
          "override": null,
          "status": null,
          "isCurrentMonth": true
      },
      "feb": null,
      "mar": null,
      "apr": null,
      "may": null,
      "jun": null,
      "jul": null,
      "aug": {
        "id": "1212",
        "msrp": "1212",
        "override": null,
        "status": null,
        "isCurrentMonth": true
      },
      "sep": {
        "id": '32434',
        "msrp": "null",
        "override": "6879",
        "status": '6798',
        "isCurrentMonth": false
      },
      "oct": {
        "id": "787980",
        "msrp": "90809",
        "override": "4576879",
        "status": null,
        "isCurrentMonth": false
      },
      "nov": {
        "id": "345",
        "msrp": "679809",
        "override": "123434",
        "status": null,
        "isCurrentMonth": false
      },
      "dec": {
        "id": "5657",
        "msrp": "6769870",
        "override": "87979",
        "status": null,
        "isCurrentMonth": false
      }
    }
  ]
}

export const VATRateData = {
  "years": [
    2021,
    2022,
    2023
  ],
  "vats": [
    {
      "version": "2021K08",
      "jan": "0.35",
      "feb": "0.36",
      "mar": "0.69",
      "apr": "0.77",
      "may": "0.88",
      "jun": null,
      "jul": null,
      "aug": null,
      "sep": null,
      "oct": null,
      "nov": null,
      "dec": null
    }
  ]
}
