
import { defineComponent, onMounted, reactive, toRefs, onBeforeUnmount } from 'vue'
import '@grapecity/spread-sheets/styles/gc.spread.sheets.excel2016colorful.css'
import GC from '@grapecity/spread-sheets'
import { initSpread, bindDataTable, usetableHeight } from './utils'
import { getVATRate } from '@/API/sysParam'
import { VATRateProps, SearchParams } from '../types'
// import { message } from 'ant-design-vue'
import { VATRateData } from '../data'
import moment from 'moment';
export default defineComponent({
    name: '',
    setup() {
        const searchParams = reactive<SearchParams>({
            yearValue: 0,
        })

        // 获取表格的高度
        const { tableHeight } = usetableHeight('#ssHost', '#ssHostvp')
        
        // 设置初始化数据变量
        const dataSource = reactive<VATRateProps>({
            vats: [],
            years: [],
        })
        
        // 初始化Spread
        // sheet当前表格的初始化
        let workbook: GC.Spread.Sheets.Workbook
        const ssHotTopInit = () => {
            if (!workbook) {
                const spread = new GC.Spread.Sheets.Workbook(document.getElementById('ssHost') as HTMLElement);
                workbook = spread;
                initSpread(workbook, dataSource.vats)
            }
        
            // 绑定数据
            bindDataTable(workbook, dataSource.vats);
        }
        
        // 获取数据
        const initVATRate = () => {
            const params = {
                year: searchParams.yearValue ?  searchParams.yearValue : moment().format('YYYY'),
            }
            Object.assign(dataSource, VATRateData)
            dataSource.vats = VATRateData.vats;
            dataSource.years = VATRateData.years;
            getVATRate({params}).then(res => {
                dataSource.vats = res.vats;
                dataSource.years = res.years;
                // 查询条件初始化
                searchParams.yearValue = searchParams.yearValue || (dataSource.years.length && dataSource.years[0])
                ssHotTopInit()
            })
        }

        const searchFn = () => {
            initVATRate()
        }
        
        onMounted(() => {
            initVATRate()
        })

        onBeforeUnmount(() => {
            workbook.destroy();
        })

        return {
            // table的高度
            tableHeight,
            // save,

            ...toRefs(dataSource),
            searchParams,
            searchFn
        }

    }
 });
