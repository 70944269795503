import GC from '@grapecity/spread-sheets'
import { IcolInfosData, VATRateData } from '../types'
import { reactive, toRefs, onMounted,  } from 'vue';

interface TablePosition {
    tableHeight: number;
}

const headerData: IcolInfosData[] = [
{
    displayName: 'Kufri',
    name: 'version'
},{
    displayName: 'Jan',
    name: 'jan',
},{
    displayName: 'Feb',
    name: 'feb',
},{
    displayName: 'Mar',
    name: 'mar',
},{
    displayName: 'Apr',
    name: 'apr',
},{
    displayName: 'May',
    name: 'may',
},{
    displayName: 'Jun',
    name: 'jun',
},{
    displayName: 'Jul',
    name: 'jul',
},{
    displayName: 'Aug',
    name: 'aug',
},{
    displayName: 'Sep',
    name: 'sep',
},{
    displayName: 'Oct',
    name: 'oct',
},{
    displayName: 'Nov',
    name: 'nov',
},{
    displayName: 'Dec',
    name: 'dec',
}]


// 绑定数据
export const bindDataTable = (spread: GC.Spread.Sheets.Workbook, data: VATRateData[]) => {
    // 挂起
    spread.suspendPaint();
    const sheet = spread.getActiveSheet();

    // 每次绑定数据时清除表单中的数据、样式
    sheet.clear(0,0,sheet.getRowCount(),sheet.getColumnCount(), GC.Spread.Sheets.SheetArea.viewport, GC.Spread.Sheets.StorageType.data)
    sheet.clear(0,0,sheet.getRowCount(),sheet.getColumnCount(), GC.Spread.Sheets.SheetArea.viewport, GC.Spread.Sheets.StorageType.style)
   
    data.forEach((item,rowIndex) => {
        sheet.setRowHeight(rowIndex, 40, GC.Spread.Sheets.SheetArea.viewport);
        sheet.autoFitRow(rowIndex);
        // 隔行变色
        (rowIndex%2 === 1) && sheet.getRange(rowIndex, -1, 1, -1, GC.Spread.Sheets.SheetArea.viewport).backColor('#f0f0f0')
    })
    sheet.autoGenerateColumns = false;
    sheet.setDataSource(data);
    sheet.getRange(0, -1, 1, -1, GC.Spread.Sheets.SheetArea.colHeader).backColor('#bfbfbf')
    sheet.bindColumns(headerData);

    spread.resumePaint();
}

export const initSpread = (spread: GC.Spread.Sheets.Workbook, data: any) => {
    spread.suspendPaint();
    const sheet = spread.getActiveSheet();
    const spreadNS = GC.Spread.Sheets;

    // 设置表头高度
    // sheet.setRowHeight(0, 40, GC.Spread.Sheets.SheetArea.colHeader);

    // 设置单元格宽高
    // sheet.defaults.rowHeight = 40;
    sheet.defaults.colWidth = 110;

    // 设置列数
    sheet.setColumnCount(headerData.length);
    
    
    // 设置隐藏行头
    sheet.options.rowHeaderVisible = false
    // 设置隐藏列头
    // sheet.options.colHeaderVisible = false

    // sheet是否显示x
    spread.options.tabNavigationVisible = false
    spread.options.tabStripVisible = false
    // sheet.options.clipBoardOptions = spreadNS.ClipboardPasteOptions.values;
    //填充铺满整个canvas
    spread.options.scrollbarMaxAlign = true;
    spread.options.scrollByPixel = true

    // 要设置允保护，不允许的才能生效
    sheet.options.isProtected = true;
    // 不允许单个格子编辑，是样式控制
    const sheetStyle = sheet.getDefaultStyle();
    //修改并设置表的默认样式locked为false.
    sheetStyle.locked = true;
    //默认对其方式
    sheetStyle.hAlign = spreadNS.HorizontalAlign.center;
    sheetStyle.vAlign = spreadNS.VerticalAlign.center;
    sheet.setDefaultStyle(sheetStyle);

    const option = {
        allowSelectLockedCells: true,
        allowSelectUnlockedCells: true,
        allowResizeRows: false,
        allowResizeColumns: false,
        allowEditObjects: false,
        allowDragInsertRows: false,
        allowDragInsertColumns: false,
        allowInsertRows: false,
        allowInsertColumns: false,
        allowDeleteRows: false,
        allowDeleteColumns: false
    };
    sheet.options.protectionOptions = option;
    
    spread.resumePaint();
}

// 计算表格的高度
export const usetableHeight = (id: string, idTop?: string) => {
    const tablePosition = reactive<TablePosition>({tableHeight: 200});
    const resizeHandler = (): void => {
        try {
            // 获取body的高度
            const clientHeight: number = document.body.clientHeight;
            const tableOffsetTop: number = (document.querySelector(id) as HTMLDivElement).getBoundingClientRect().top;
            
            tablePosition.tableHeight = clientHeight - tableOffsetTop - 15;
            (document.querySelector(id) as HTMLDivElement).style.height = tablePosition.tableHeight + 'px'
        } catch (e) {
            console.log(e);
        }
    }
    onMounted(() => {
        resizeHandler();
        window.addEventListener('resize', resizeHandler);
    })

    return toRefs(tablePosition);
}